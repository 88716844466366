import { Component, OnInit } from '@angular/core';
import { catchError, first } from 'rxjs/operators';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'ee-unsubscribe-page',
  templateUrl: './unsubscribe-page.component.html',
  styleUrls: ['./unsubscribe-page.component.scss']
})
export class UnsubscribePageComponent implements OnInit {
  gifUrl = null;
  videoUrl: string = null;

  constructor(
    private apiCommsService: ApiCommsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    //this.getGiphy();
    this.getVideos();
    this.unsubscribeUser();
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('bye')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  private unsubscribeUser() {
    if (this.route.snapshot.queryParams.token) {
      this.apiCommsService
        .unsubscribeUser(this.route.snapshot.queryParams.token)
        .subscribe();
    }
  }

  private getVideos() {
    this.apiCommsService
      .getCloudinaryVideo('bye')
      .pipe(
        first(),
        catchError(err => {
          console.warn('Cloudinary video fetch failed — using Giphy fallback');
          this.getGiphy(); // fallback
          return of(null);
        })
      )
      .subscribe(url => {
        if (url) {
          this.videoUrl = url;
        }
      });
  }

  onVideoMetadata(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.muted = true;
  }
}
