import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { catchError, first, tap } from 'rxjs/operators';
import { MaintenanceModeService } from '../../services/maintenance-mode/maintenance-mode.service';
import { MaintenanceModeSettings } from '../../interfaces/maintenance-mode-settings';
import { Router } from '@angular/router';
import { AppRoutes } from '../../enums/app-routes';
import { of } from 'rxjs';

@Component({
  selector: 'ee-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  maintenanceModeSettings: MaintenanceModeSettings = null;
  gifUrl = null;
  videoUrl: string = null;
  @ViewChild('videoPlayer') videoRef: ElementRef<HTMLVideoElement>;

  constructor(
    private apiCommsService: ApiCommsService,
    private router: Router,
    private maintenanceModeService: MaintenanceModeService
  ) {}

  ngOnInit() {
    this.getMaintenanceModeSettings();
    this.getVideos();
    //this.getGiphy();
  }

  private getMaintenanceModeSettings() {
    this.maintenanceModeService
      .getMaintenanceModeSettings()
      .pipe(
        first(),
        tap(response => {
          const stillOnMaintenancePage =
            this.router.url.includes('maintenance');
          if (
            response &&
            !response.maintenanceModeActive &&
            stillOnMaintenancePage
          ) {
            this.router.navigate([AppRoutes.LOGIN]);
          }
        })
      )
      .subscribe(response => {
        this.maintenanceModeSettings = response;
      });
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('sleep')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  private getVideos() {
    this.apiCommsService
      .getCloudinaryVideo('maintenance')
      .pipe(
        first(),
        catchError(err => {
          console.warn('Cloudinary video fetch failed — using Giphy fallback');
          this.getGiphy(); // fallback
          return of(null);
        })
      )
      .subscribe(url => {
        if (url) {
          this.videoUrl = url;
        }
      });
  }

  onVideoMetadata(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.muted = true;
  }
}
