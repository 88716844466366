import { Component, OnInit } from '@angular/core';
import { catchError, first } from 'rxjs/operators';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';

@Component({
  selector: 'ee-restricted-page',
  templateUrl: './restricted-page.component.html',
  styleUrls: ['./restricted-page.component.scss']
})
export class RestrictedPageComponent implements OnInit {
  gifUrl = null;
  moduleError = false;
  videoUrl: string = null;

  constructor(
    private apiCommsService: ApiCommsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    //this.getGiphy();
    this.getVideos();
    this.moduleError = this.route.snapshot.data
      ? this.route.snapshot.data.module
      : false;
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('denied')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  private getVideos() {
    this.apiCommsService
      .getCloudinaryVideo('denied')
      .pipe(
        first(),
        catchError(err => {
          console.warn('Cloudinary video fetch failed — using Giphy fallback');
          this.getGiphy(); // fallback
          return of(null);
        })
      )
      .subscribe(url => {
        if (url) {
          this.videoUrl = url;
        }
      });
  }

  onVideoMetadata(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.muted = true;
  }

  requestAccess() {
    const link = document.createElement('a');
    link.href =
      'mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Request%20Access';
    link.click();
    link.remove();
  }
}
