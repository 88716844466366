<div class="bg-container"></div>
<div class="content">
  <div class="main-content">
    <h2>Error</h2>
    <p>
      <span>There was an error loading the requested page.</span>
    </p>
    <p *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </p>
    <p>
      Click the button below or contact Strategic Innovation at
      <a href="mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Error"
        >sci&#64;det.nsw.edu.au</a
      >
      for assistance.
      <br /><br />
      <button mat-button class="report-error" (click)="reportError()">
        Report Error
      </button>
    </p>
  </div>
</div>
