import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { MapObject } from '../../interfaces/map-object';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MapReferenceLayer } from '../../interfaces/map-reference-layer';
import { MapReferenceLayersService } from '../../services/map-reference-layers/map-reference-layers.service';
import { DragulaService } from 'ng2-dragula';
import { HelpService } from '../../../core/services/help/help.service';
import * as moment from 'moment';
import { MapTooltipService } from '../../services/map-tooltip/map-tooltip.service';
import { UserEventCategories } from '../../enums/user-event-categories';
import { UserEvents } from '../../enums/user-events';
import { UserEventService } from '../../../core/services/user-event/user-event.service';
import { MatDialog } from '@angular/material/dialog';
import { MapPaletteDialogComponent } from '../map-palette-dialog/map-palette-dialog.component';

@Component({
  selector: 'ee-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss']
})
export class MapLegendComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() mapObject: MapObject;
  @Input() mapLegendTooltipEnabled = true;
  @Input() mapId: string = null;

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  private initialised = false;
  dragGroup = 'REF_LAYER_DRAG_GROUP';

  legendExpanded = true;
  // display: means there are filters applied, no matter on or off
  // active: at least one filter is on
  legendFilterStatus = { display: false, active: false };

  referenceLayers: MapReferenceLayer[] = [];

  mapZoomLevel = null;
  zoomEvent = null;
  tooltipVisible = false;

  constructor(
    private mapReferenceLayersService: MapReferenceLayersService,
    private mapTooltipService: MapTooltipService,
    private dragulaService: DragulaService,
    private helpService: HelpService,
    private userEventService: UserEventService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.mapReferenceLayersService.onFilterChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        // Update your legend based on the layer's filter status
        // All necessary information is on the layer itself
        this.updateFilterStatus();
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initialiseDragula();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.mapObject &&
      this.mapObject &&
      this.mapObject.map &&
      !this.initialised
    ) {
      this.initialised = true;
      this.tooltipVisible = this.mapObject.tooltipEnabled;
      this.getReferenceLayers();
      this.createMapEvents();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
    this.dragulaService.destroy(this.dragGroup);
    this.destroyMapEvents();
  }

  private initialiseDragula() {
    // Create drag group
    this.dragGroup += this.mapId;
    this.dragulaService.createGroup(this.dragGroup, {
      moves: (el, container, handle) => {
        return !(
          handle &&
          handle.className &&
          handle.className.toString().split(' ').includes('handle-ignore')
        );
      }
    });

    this.dragulaService
      .drop(this.dragGroup)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.mapReferenceLayersService.reorderEnabledLayers(
          this.mapObject,
          this.referenceLayers
        );
      });
  }

  private createMapEvents() {
    this.zoomEvent = () => {
      this.mapZoomLevel = this.mapObject.map.getZoom();
    };
    this.zoomEvent();
    this.mapObject.map.on('zoom', this.zoomEvent);
  }

  private destroyMapEvents() {
    if (this.mapObject && this.mapObject.map) {
      this.mapObject.map.off('zoom', this.zoomEvent);
    }
  }

  private getReferenceLayers() {
    // update reference layers
    this.mapObject.onReferenceLayersReady
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          this.referenceLayers = this.mapObject.enabledReferenceLayers;
          setTimeout(() => this.helpService.refreshHints());
        }
      });

    // update filter status
    this.mapObject.onReferenceLayersChanged
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        this.updateFilterStatus();
      });
  }

  private updateFilterStatus() {
    if (this.mapObject && this.mapObject.enabledReferenceLayers) {
      this.legendFilterStatus.display =
        this.mapReferenceLayersService.checkMapFilterStatus(this.mapObject);

      this.legendFilterStatus.active =
        this.mapReferenceLayersService.checkMapActiveFilterStatus(
          this.mapObject
        );
    }
  }

  removeLayer(layer: MapReferenceLayer) {
    if (this.mapObject) {
      this.mapReferenceLayersService.removeVectorLayer(this.mapObject, layer);
    }
  }

  showPalette(layer: MapReferenceLayer) {
    if (layer) {
      const paletteGroup = this.mapReferenceLayersService.getLayerPaletteData(
        this.mapObject,
        layer
      );

      const dialogRef = this.dialog.open(MapPaletteDialogComponent, {
        width: '380px',
        data: {
          layerName: layer.displayName,
          paletteGroup: paletteGroup,
          stylingOverwritten: layer.stylingOverwritten
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === -1) {
            this.mapReferenceLayersService.resetLayerStyling(
              this.mapObject,
              layer,
              paletteGroup
            );
          } else {
            this.mapReferenceLayersService.updateLayerStyling(
              this.mapObject,
              layer,
              result
            );
          }
        }
      });
    }
  }

  toggleLayerVisibility(layer: MapReferenceLayer) {
    if (this.mapObject) {
      this.mapReferenceLayersService.toggleLayerVisibility(
        this.mapObject,
        layer
      );
    }
  }

  toggleLayerFilter(layer: MapReferenceLayer, status?: 'on' | 'off') {
    if (this.mapObject) {
      this.mapReferenceLayersService.togglePropertyFilter(
        this.mapObject,
        layer,
        status
      );
      this.mapReferenceLayersService.toggleSpatialFilter(
        this.mapObject,
        layer,
        status
      );
    }
    this.updateFilterStatus();
  }

  toggleAllFilters(event: any, state: 'on' | 'off') {
    // Prevent click through to accordion button
    event.preventDefault();
    event.cancelBubble = true;
    // Toggle the filter for all layers that are enabled
    this.referenceLayers.forEach(layer => {
      this.mapReferenceLayersService.togglePropertyFilter(
        this.mapObject,
        layer,
        state
      );
      this.mapReferenceLayersService.toggleSpatialFilter(
        this.mapObject,
        layer,
        state
      );
    });
    this.updateFilterStatus();
  }

  hasFilter(layer: MapReferenceLayer) {
    return this.mapReferenceLayersService.checkLayerFilter(layer);
  }

  hasActiveFilter(layer: MapReferenceLayer) {
    return this.mapReferenceLayersService.checkLayerFilterStatus(layer);
  }

  toggleMapToolTips() {
    this.tooltipVisible = !this.tooltipVisible;

    // Toggle off the tooltip for all layers that are enabled
    if (this.mapObject.tooltipEnabled) {
      this.mapObject.tooltipEnabled = false;
      this.mapTooltipService.hideTooltip(this.mapObject);

      this.userEventService.logUserEvent({
        category: UserEventCategories.REFERENCE_LAYERS,
        event: UserEvents.REFERENCE_LAYER_TOOLTIP_OFF
      });
    } else {
      this.mapObject.tooltipEnabled = true;

      this.userEventService.logUserEvent({
        category: UserEventCategories.REFERENCE_LAYERS,
        event: UserEvents.REFERENCE_LAYER_TOOLTIP_ON
      });
    }
  }

  refreshHints() {
    setTimeout(() => this.helpService.refreshHints());
  }

  getStreamTooltip(layer: MapReferenceLayer) {
    let timeRemaining = moment
      .duration(layer.refreshTimeRemaining, 'ms')
      .humanize(true);
    timeRemaining =
      timeRemaining.charAt(0).toUpperCase() + timeRemaining.substring(1);

    return `
      <span class="bold">Last Updated: </span> ${moment(
        layer.refreshDate
      ).format('DD MMMM YYYY, h:mm A')}<br> \
      <span class="bold">Next Update: </span> ${timeRemaining}
    `;
  }
}
