<div class="bg-container"></div>
<div class="content">
  <div class="logo-container" *ngIf="!moduleError">
    <a href="../..">
      <img src="../../../../assets/images/eagle-eye-logo.svg" />
      <div class="title">EAGLE EYE</div>
    </a>
  </div>
  <div class="help-container" *ngIf="!moduleError">
    <a
      href="mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Request%20Access"
      >Need help?</a
    >
  </div>
  <div class="main-content">
    <h2>Restricted Access</h2>
    <p>
      <span *ngIf="!moduleError">Eagle Eye is a private system.</span>
      <span *ngIf="moduleError">
        You currently do not have access to this module.
      </span>
      <br /><br />
      <!-- <img [src]="gifUrl" *ngIf="gifUrl" class="gif" /> -->
      <ng-container *ngIf="videoUrl; else gifBlock">
        <video
          #videoPlayer
          [src]="videoUrl"
          autoplay
          muted
          loop
          playsinline
          preload="auto"
          [attr.key]="videoUrl"
          class="restricted-video"
          (loadedmetadata)="onVideoMetadata($event)"
        ></video>
      </ng-container>

      <ng-template #gifBlock>
        <img
          *ngIf="gifUrl"
          [src]="gifUrl"
          alt="Fallback maintenance GIF"
          class="gif"
        />
      </ng-template>
      <br /><br />
      Click the button below or contact Strategic Innovation at
      <a
        href="mailto:sci@det.nsw.edu.au?subject=Eagle%20Eye%20-%20Request%20Access"
        >sci&#64;det.nsw.edu.au</a
      >
      to request access.
      <br /><br />
      <button mat-button class="request-access" (click)="requestAccess()">
        Request Access
      </button>
    </p>
  </div>
</div>
