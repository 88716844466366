import { Component, OnInit } from '@angular/core';
import { ApiCommsService } from '../../services/api-comms/api-comms.service';
import { catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'ee-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
  gifUrl = null;
  videoUrl: string = null;

  constructor(private apiCommsService: ApiCommsService) {}

  ngOnInit() {
    //this.getGiphy();
    this.getVideos();
  }

  private getGiphy() {
    this.apiCommsService
      .getGiphyImage('construction')
      .pipe(first())
      .subscribe(result => {
        if (result) {
          this.gifUrl = result;
        }
      });
  }

  private getVideos() {
    this.apiCommsService
      .getCloudinaryVideo('construction')
      .pipe(
        first(),
        catchError(err => {
          console.warn('Cloudinary video fetch failed — using Giphy fallback');
          this.getGiphy(); // fallback
          return of(null);
        })
      )
      .subscribe(url => {
        if (url) {
          this.videoUrl = url;
        }
      });
  }

  onVideoMetadata(event: Event) {
    const video = event.target as HTMLVideoElement;
    video.muted = true;
  }
}
