<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <div class="content">
    <img src="assets/images/under-construction/1.gif" />
    <marquee>Coming Soon</marquee>
    <!-- <img [src]="gifUrl" *ngIf="gifUrl" /> -->
    <ng-container *ngIf="videoUrl; else gifBlock">
      <video
        #videoPlayer
        [src]="videoUrl"
        autoplay
        muted
        loop
        playsinline
        preload="auto"
        [attr.key]="videoUrl"
        class="coming-soon-video"
        (loadedmetadata)="onVideoMetadata($event)"
      ></video>
    </ng-container>

    <ng-template #gifBlock>
      <img *ngIf="gifUrl" [src]="gifUrl" alt="Fallback maintenance GIF" />
    </ng-template>
    <marquee>Coming Soon</marquee>
    <img src="assets/images/under-construction/1.gif" />
  </div>
</div>
