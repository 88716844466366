import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MapReferenceLayerCategory } from '../../interfaces/map-reference-layer-category';
import { MapReferenceLayer } from '../../interfaces/map-reference-layer';
import { MapReferenceLayersService } from '../../services/map-reference-layers/map-reference-layers.service';
import { FormControl } from '@angular/forms';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { MapObject } from '../../interfaces/map-object';
import { Subject } from 'rxjs';
import { UserEventCategories } from '../../enums/user-event-categories';
import { UserEvents } from '../../enums/user-events';
import { UserEventService } from '../../../core/services/user-event/user-event.service';
import { UserFavouritesService } from '../../../admin/services/user-favourites/user-favourites.service';
import { MapLayerActionType } from '../../enums/map-layer-action-type';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { cloneDeep } from 'lodash';
import { UserData } from 'src/app/admin/interfaces/user-data';
import { CurrentUserResolverService } from 'src/app/core/resolvers/current-user-resolver/current-user-resolver.service';
import { MapFavoriteLayerResolverService } from 'src/app/core/resolvers/map-favorite-layer-resolver/map-favorite-layer-resolver.service';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/app/core/enums/app-routes';
import { HubRoutes } from 'src/app/hub/enums/hub-routes';
import { HubMappingService } from 'src/app/hub/services/hub-mapping/hub-mapping.service';
import { HubSchoolViewMappingService } from 'src/app/hub/services/hub-school-view-mapping/hub-school-view-mapping.service';

@Component({
  selector: 'ee-map-reference-layer-panel',
  templateUrl: './map-reference-layer-panel.component.html',
  styleUrls: ['./map-reference-layer-panel.component.scss']
})
export class MapReferenceLayerPanelComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() mapObject: MapObject = null;
  @Output() closeButtonClick = new EventEmitter();
  @ViewChild(PerfectScrollbarDirective) refLayerList: PerfectScrollbarDirective;
  @Input() isOpen: boolean;

  hasAtLeastOneResult = true;
  showClear = false;
  searchValue = new FormControl();
  referenceLayerCategories: MapReferenceLayerCategory[];
  originalReferenceLayerCategories: MapReferenceLayerCategory[];
  private initialised = false;
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();
  showNewLayers: boolean = false;
  showingFavoriteLayers: boolean = false;
  favoriteLayers: any[] = [];
  currentUser: UserData;
  filteredReferenceLayerCategories: MapReferenceLayerCategory[];
  favoriteLayerCategory: MapReferenceLayerCategory = {
    categoryId: 999999999,
    category: 'My Favourites',
    icon: 'star',
    layers: [],
    subCategories: [],
    selectable: true,
    expanded: true,
    hiddenBySearch: false,
    parent_category_id: null
  };
  private initialReferenceLayerCategories: MapReferenceLayerCategory[];

  constructor(
    private router: Router,
    private mapReferenceLayerService: MapReferenceLayersService,
    private mapFavLayersService: MapFavoriteLayerResolverService,
    private hubMappingService: HubMappingService,
    private hubSchoolViewMappingService: HubSchoolViewMappingService,
    private userEventService: UserEventService,
    private favoritesService: UserFavouritesService,
    private cdr: ChangeDetectorRef,
    private currentUserResolverService: CurrentUserResolverService
  ) {}

  ngOnInit() {
    this.currentUser =
      this.currentUserResolverService.getCurrentUserDataValue();

    this.loadFavoriteLayersFromLocalStorage();
    this.watchSearchValue();
  }

  private loadFavoriteLayersFromLocalStorage() {
    const storedFavorites = localStorage.getItem('favoriteLayers');
    if (storedFavorites) {
      try {
        const parsedFavorites = JSON.parse(storedFavorites);
        if (Array.isArray(parsedFavorites)) {
          this.favoriteLayers = Array.from(new Set(parsedFavorites));
          this.loadFavorites(this.favoriteLayers);
        } else {
          console.error(
            'Stored favorite layers format is not an array:',
            storedFavorites
          );
        }
      } catch (error) {
        console.error(
          'Error parsing favorite layers from localStorage:',
          error
        );
      }
    }
  }

  refreshFavoriteLayers(): void {
    if (!this.referenceLayerCategories) {
      console.warn('referenceLayerCategories is undefined or null');
      return;
    }
    this.loadFavoriteLayersFromLocalStorage();
    if (this.referenceLayerCategories && this.favoriteLayerCategory) {
      const favCategoryIndex = this.referenceLayerCategories.findIndex(
        cat => cat.categoryId === this.favoriteLayerCategory.categoryId
      );

      if (favCategoryIndex === -1) {
        if (this.favoriteLayerCategory.layers.length > 0) {
          this.referenceLayerCategories.unshift(this.favoriteLayerCategory);
        }
      } else {
        this.referenceLayerCategories[favCategoryIndex].layers = [
          ...this.favoriteLayerCategory.layers
        ];

        // Remove "My Favourites" category if it has no layers
        if (
          this.referenceLayerCategories[favCategoryIndex].layers.length === 0
        ) {
          this.referenceLayerCategories.splice(favCategoryIndex, 1);
        }
      }
      this.cdr.detectChanges();
    } else {
      console.warn(
        'Cannot refresh favorite layers as referenceLayerCategories or favoriteLayerCategory is undefined'
      );
    }
  }

  loadFavorites(favoriteLayerIds: number[]) {
    this.favoriteLayerCategory.layers = [];
    const favoriteLayersSet = new Set(favoriteLayerIds);
    const favoriteLayers = [];

    const traverseCategories = categories => {
      categories.forEach(cat => {
        if (cat.layers) {
          cat.layers.forEach(layer => {
            if (favoriteLayersSet.has(Number(layer.layerId))) {
              layer.favorite = true;
              if (
                !favoriteLayers.some(
                  favLayer => favLayer.layerId === layer.layerId
                )
              ) {
                favoriteLayers.push(layer);
              }
            } else {
              layer.favorite = false;
            }
          });
        }
        if (cat.subCategories) {
          traverseCategories(cat.subCategories);
        }
      });
    };
    traverseCategories(this.referenceLayerCategories);
    this.favoriteLayerCategory.layers = favoriteLayers;

    this.referenceLayerCategories = this.referenceLayerCategories.filter(
      category => category.categoryId !== this.favoriteLayerCategory.categoryId
    );

    if (favoriteLayers.length > 0) {
      this.referenceLayerCategories.unshift(this.favoriteLayerCategory);
    }

    this.originalReferenceLayerCategories = cloneDeep(
      this.referenceLayerCategories
    );
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen && this.isOpen) {
      this.refreshFavoriteLayers();
    }

    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.watchReferenceLayers();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  toggleShowLayers() {
    this.showNewLayers = !this.showNewLayers;

    if (this.showNewLayers) {
      const filterCategories = (categories: MapReferenceLayerCategory[]) => {
        return categories.reduce((filtered, category) => {
          const newLayers = category.layers?.filter(layer => layer.isNew);
          const subCategories = category.subCategories
            ? filterCategories(category.subCategories)
            : [];

          if (newLayers.length || subCategories.length > 0) {
            filtered.push({
              ...category,
              layers: newLayers,
              subCategories,
              expanded: true
            });
          }
          return filtered;
        }, []);
      };
      const filteredCategories = filterCategories(
        cloneDeep(this.initialReferenceLayerCategories)
      );
      const newFavoriteLayers =
        this.favoriteLayerCategory.layers?.filter(layer => layer.isNew) || [];

      if (newFavoriteLayers.length > 0) {
        filteredCategories.unshift({
          ...this.favoriteLayerCategory,
          layers: newFavoriteLayers
        });
      }
      this.referenceLayerCategories = filteredCategories;
      this.expandCategories(this.referenceLayerCategories);
    } else {
      this.referenceLayerCategories = cloneDeep(
        this.initialReferenceLayerCategories
      );

      const favCategoryIndex = this.referenceLayerCategories.findIndex(
        cat => cat.categoryId === this.favoriteLayerCategory.categoryId
      );

      if (
        favCategoryIndex === -1 &&
        this.favoriteLayerCategory.layers?.length > 0
      ) {
        this.referenceLayerCategories.unshift(this.favoriteLayerCategory);
      } else {
        this.referenceLayerCategories[favCategoryIndex].layers = [
          ...new Set([
            ...(this.favoriteLayerCategory.layers || []),
            ...this.referenceLayerCategories[favCategoryIndex].layers.filter(
              layer =>
                !this.favoriteLayerCategory.layers.some(
                  favLayer => favLayer.layerId === layer.layerId
                )
            )
          ])
        ];
        // Remove "My Favourites" category if it has no layers
        if (
          this.referenceLayerCategories[favCategoryIndex].layers.length === 0
        ) {
          this.referenceLayerCategories.splice(favCategoryIndex, 1);
        }
      }
      this.collapseCategories(this.referenceLayerCategories);
      this.cdr.detectChanges();
    }
  }

  private filterCategories(
    categories: MapReferenceLayerCategory[],
    onlyFavorites: boolean = false
  ): MapReferenceLayerCategory[] {
    return categories.reduce((filtered, category) => {
      const filteredCategory: MapReferenceLayerCategory = { ...category };

      if (category.layers) {
        filteredCategory.layers = category.layers.filter(
          layer => !onlyFavorites || layer.favorite
        );
      }

      if (category.subCategories) {
        filteredCategory.subCategories = this.filterCategories(
          category.subCategories,
          onlyFavorites
        );
      }

      if (
        (filteredCategory.layers && filteredCategory.layers.length > 0) ||
        (filteredCategory.subCategories &&
          filteredCategory.subCategories.some(
            subCat => subCat.layers && subCat.layers.length > 0
          ))
      ) {
        filtered.push(filteredCategory);
      }

      return filtered;
    }, []);
  }

  toggleFavorite(layer: any) {
    const originalState = layer.favorite;
    layer.favorite = !layer.favorite;

    if (layer.favorite) {
      this.favoriteLayers.push(layer.layerId);
      this.favoritesService
        .addFavorite(this.currentUser.userId, layer.layerId)
        .subscribe({
          next: () => {
            this.updateLocalStorage();
            this.refreshFavoriteLayers();
          },
          error: () => {
            layer.favorite = originalState;
            this.favoriteLayers = this.favoriteLayers.filter(
              id => id !== layer.layerId
            );
          }
        });
    } else {
      this.favoriteLayers = this.favoriteLayers.filter(
        id => id !== layer.layerId
      );

      this.favoritesService
        .removeFavorite(this.currentUser.userId, layer.layerId)
        .subscribe({
          next: () => {
            this.updateLocalStorage();
            this.refreshFavoriteLayers();
          },
          error: () => {
            layer.favorite = originalState;
            this.favoriteLayers.push(layer.layerId);
          }
        });
    }
  }

  syncFavoritesFromBackend() {
    this.mapFavLayersService.resolve().subscribe(favoriteLayers => {
      this.favoriteLayers = Array.from(new Set(favoriteLayers));
      this.updateLocalStorage();
      this.loadFavorites(this.favoriteLayers);
    });
  }

  updateLocalStorage() {
    const serializedFavorites = JSON.stringify(this.favoriteLayers);
    localStorage.setItem('favoriteLayers', serializedFavorites);

    localStorage.setItem('favoriteLayersUpdate', Date.now().toString());
  }

  addLayerToFavorites(layer: any) {
    this.favoriteLayerCategory.layers.push(layer);
    this.favoriteLayers.push(layer.layerId);

    if (
      !this.referenceLayerCategories.find(
        cat => cat.categoryId === this.favoriteLayerCategory.categoryId
      )
    ) {
      this.referenceLayerCategories.unshift(this.favoriteLayerCategory);
    }

    this.updateLayerInCategories(layer, true);
    this.cdr.detectChanges();
  }

  removeLayerFromFavorites(layer: any) {
    this.favoriteLayerCategory.layers =
      this.favoriteLayerCategory.layers.filter(
        favLayer => favLayer.layerId !== layer.layerId
      );
    this.favoriteLayers = this.favoriteLayers.filter(
      id => id !== layer.layerId
    );

    if (this.favoriteLayerCategory.layers.length === 0) {
      this.referenceLayerCategories = this.referenceLayerCategories.filter(
        cat => cat.categoryId !== this.favoriteLayerCategory.categoryId
      );
    }

    this.updateLayerInCategories(layer, false);
    this.cdr.detectChanges();
  }

  updateLayerInCategories(layer: any, favorite: boolean) {
    const updateFavoriteStatus = (categories: MapReferenceLayerCategory[]) => {
      categories.forEach(category => {
        category.layers.forEach(catLayer => {
          if (catLayer.layerId === layer.layerId) {
            catLayer.favorite = favorite;
          }
        });
        if (category.subCategories) {
          updateFavoriteStatus(category.subCategories);
        }
      });
    };

    updateFavoriteStatus(this.referenceLayerCategories);
  }

  hasData(): boolean {
    const checkForNewLayers = (
      categories: MapReferenceLayerCategory[]
    ): boolean => {
      return categories.some(
        category =>
          category.layers?.some(layer => layer.isNew) ||
          (category.subCategories && checkForNewLayers(category.subCategories))
      );
    };

    return this.referenceLayerCategories
      ? checkForNewLayers(this.referenceLayerCategories)
      : false;
  }

  private expandCategories(categories: MapReferenceLayerCategory[]) {
    categories.forEach(category => {
      category.expanded = true;

      if (category.subCategories && category.subCategories.length > 0) {
        this.expandCategories(category.subCategories);
      }
    });
  }

  private collapseCategories(categories: MapReferenceLayerCategory[]) {
    categories.forEach(category => {
      category.expanded = false;

      if (category.subCategories && category.subCategories.length > 0) {
        this.expandCategories(category.subCategories);
      }
    });
  }

  private watchReferenceLayers() {
    this.mapObject.onReferenceLayersReady
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(result => {
        if (result) {
          this.referenceLayerCategories =
            this.mapObject.referenceLayerCategories;
          this.initialReferenceLayerCategories = cloneDeep(
            this.mapObject.referenceLayerCategories
          );
          this.updateReferenceLayerResults('');
        }
      });
  }

  // Watch for search value change
  private watchSearchValue() {
    this.searchValue.valueChanges
      .pipe(
        debounceTime(200),
        tap(searchValue => {
          this.showClear = searchValue !== '' && searchValue !== null;
        }),
        map(searchValue => {
          if (typeof searchValue === 'string') {
            return searchValue;
          } else {
            return null;
          }
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(searchValue => {
        this.updateReferenceLayerResults(searchValue);
      });
  }

  private updateReferenceLayerResults(searchValue: string) {
    if (searchValue) {
      searchValue = searchValue.toLowerCase();
    }

    this.hasAtLeastOneResult = false;

    const expandParent = (
      category: MapReferenceLayerCategory,
      value: string
    ) => {
      if (category) {
        category.hiddenBySearch = false;

        if (value === null || value === '') {
          category.expanded = !!category.parentCategory;
        } else {
          category.expanded = true;
        }

        if (category.parentCategory) {
          expandParent(category.parentCategory, value);
        }
      }
    };

    const results: MapReferenceLayer[] = [];

    // Iterate through subcategories and test each layer
    const checkSubCategories = (subCategories: MapReferenceLayerCategory[]) => {
      for (const subCat of subCategories) {
        let hasCategoryResult = false;

        subCat.hiddenBySearch = true;
        subCat.expanded = false;

        for (const refLayer of subCat.layers.filter(l => l.selectable)) {
          // Check whether layer should be displayed
          const match =
            !searchValue ||
            searchValue === '' ||
            (searchValue &&
              subCat.category.toLowerCase().includes(searchValue)) ||
            refLayer.displayName.toLowerCase().includes(searchValue) ||
            (refLayer.tags &&
              refLayer.tags
                .map(t => (t ? t.toLowerCase() : null))
                .indexOf(searchValue) > -1);

          refLayer.hiddenBySearch = !match;

          if (match) {
            hasCategoryResult = true;
            this.hasAtLeastOneResult = true;
            results.push(refLayer);
          }
        }

        checkSubCategories(subCat.subCategories.filter(c => c.selectable));
      }
    };

    checkSubCategories(this.referenceLayerCategories.filter(c => c.selectable));

    for (const l of results) {
      expandParent(l.category, searchValue);
    }

    // Always include the favorite layers category
    if (
      !this.referenceLayerCategories.find(
        cat => cat.categoryId === this.favoriteLayerCategory.categoryId
      )
    ) {
      this.referenceLayerCategories.unshift(this.favoriteLayerCategory);
    }

    // Update the favorite layers category based on the search criteria
    if (searchValue) {
      const favLayers = this.favoriteLayerCategory.layers.filter(
        layer =>
          layer.displayName.toLowerCase().includes(searchValue) ||
          (layer.tags &&
            layer.tags
              .map(t => (t ? t.toLowerCase() : null))
              .indexOf(searchValue) > -1)
      );
      this.favoriteLayerCategory.layers = favLayers;
      this.favoriteLayerCategory.hiddenBySearch = favLayers.length === 0;
      this.favoriteLayerCategory.expanded = favLayers.length > 0;
    } else {
      this.favoriteLayerCategory.hiddenBySearch = false;
      this.favoriteLayerCategory.layers.forEach(
        layer => (layer.hiddenBySearch = false)
      );
    }

    this.updateScrollContainer();
  }

  getExistingViewSpatialFilterValue() {
    // apply filter
    // discover views from the routing... a better way??
    const url = this.router.url;
    const [_, app, view] = url.split('/');
    if (app === AppRoutes.HUB) {
      if (view === HubRoutes.SCHOOL) {
        // school view
        return this.hubSchoolViewMappingService.getSpatialFilterSetValue();
      } else {
        // other views
        return this.hubMappingService.getSpatialFilterSetValue();
      }
    }
  }

  toggleLayer(layer: MapReferenceLayer) {
    if (this.mapObject) {
      const viewFilter = this.getExistingViewSpatialFilterValue();
      const mapActiveFilterStatus =
        this.mapReferenceLayerService.checkMapActiveFilterStatus(
          this.mapObject
        );
      if (!layer.enabled) {
        this.mapReferenceLayerService.addVectorLayer(
          this.mapObject,
          layer,
          MapLayerActionType.USER_TOGGLE
        );
        this.mapReferenceLayerService.applySpatialPropertyFilter(
          this.mapObject,
          layer,
          viewFilter,
          mapActiveFilterStatus
        );
      } else {
        this.mapReferenceLayerService.removeVectorLayer(this.mapObject, layer);
      }
      this.userEventService.logUserEvent({
        category: UserEventCategories.REFERENCE_LAYERS,
        event: layer.enabled
          ? UserEvents.REFERENCE_LAYER_ON
          : UserEvents.REFERENCE_LAYER_OFF,
        metadata: {
          layer: layer.layerName
        }
      });
    }
  }

  clearSearch() {
    this.searchValue.reset();
    this.showClear = false;

    //this.referenceLayerCategories = cloneDeep(this.originalReferenceLayerCategories);
    this.loadFavorites(this.favoriteLayers);

    this.cdr.detectChanges();
  }

  close() {
    this.closeButtonClick.emit(true);
  }

  updateScrollContainer() {
    if (this.refLayerList) {
      this.refLayerList.update();
    }
  }
}
