import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MapToolbarService } from '../../services/map-toolbar/map-toolbar.service';
import { MapMeasureMode } from '../../enums/map-measure-mode';
import { MapObject } from '../../interfaces/map-object';
import { StreetViewObject } from '../../interfaces/street-view-object';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PlannedAreaAnnotationTypesResolverService } from '../../../planned-areas/resolvers/planned-area-annotations-types-resolver/planned-area-annotations-types-resolver.service';
import { Subject } from 'rxjs';
import { PlannedAreaAnnotationTypes } from '../../../planned-areas/interfaces/planned-area-annotation-types';
import { PlannedAreaAnnotationService } from '../../../planned-areas/services/planned-area-annotations/planned-area-annotations.service';
import { MapboxService } from '../../services/mapbox/mapbox.service';
import { MapEditMode } from '../../enums/map-edit-mode';
import { AppRoutes } from '../../../core/enums/app-routes';
import { AuthService } from '../../../core/services/auth/auth.service';
import { UserRoles } from '../../../core/enums/user-roles';
import { UserEventCategories } from '../../enums/user-event-categories';
import { UserEvents } from '../../enums/user-events';
import { UserEventService } from '../../../core/services/user-event/user-event.service';
import { MapToolbarOptions } from '../../interfaces/map-toolbar-options';
import { Map3dViewService } from '../../services/map-3d-view/map-3d-view.service';
import { DownloadDisclaimerService } from '../../../core/services/download-disclaimer/download-disclaimer.service';
import { MapReferenceLayerCategory } from '../../interfaces/map-reference-layer-category';
import { HubRoutes } from 'src/app/hub/enums/hub-routes';

@Component({
  selector: 'ee-map-toolbar',
  templateUrl: './map-toolbar.component.html',
  styleUrls: ['./map-toolbar.component.scss']
})
export class MapToolbarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mapObject: MapObject;
  @Input() streetViewObject: StreetViewObject;
  @Input() toolbarOptions?: MapToolbarOptions = null;
  @Output() toolClick?: EventEmitter<string> = new EventEmitter();

  referenceLayerPanelEnabled = false;
  isPanelOpen = false;
  streetViewEnabled = false;
  basemapPanelEnabled = false;
  annotationTypes: PlannedAreaAnnotationTypes[] = [];
  otherEdit = false;
  measureEdit = false;
  inspectorEdit = false;
  reachabilityEdit = false;
  initialised = false;
  isUserGapEditor = false;
  newLayerCount: number = 0;
  MapMeasureModeEnum = MapMeasureMode;
  newLayerCountVisible: boolean = false;
  newLayerCategories: MapReferenceLayerCategory[];
  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private mapToolbarService: MapToolbarService,
    private authService: AuthService,
    private router: Router,
    private plannedAreaAnnotationTypesResolver: PlannedAreaAnnotationTypesResolverService,
    private plannedAreaAnnotationsService: PlannedAreaAnnotationService,
    private mapboxService: MapboxService,
    private userEventService: UserEventService,
    private map3dViewService: Map3dViewService,
    private downloadDisclaimerService: DownloadDisclaimerService
  ) {
    this.checkPanelClickedState();
  }

  ngOnInit() {
    this.getUserPermissions();
    this.watchAnnotationTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mapObject && this.mapObject && !this.initialised) {
      this.initialised = true;
      this.onMapEdit();
      this.watchReferenceLayers();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();

    if (this.streetViewEnabled) {
      this.mapToolbarService.disableStreetView(this.streetViewObject);
    }
  }

  private getUserPermissions() {
    this.isUserGapEditor = this.authService.checkUserRole(UserRoles.GAP_EDITOR);
  }

  private watchReferenceLayers() {
    this.mapObject.onReferenceLayersReady.subscribe(ready => {
      if (ready) {
        this.newLayerCategories = this.mapObject.referenceLayerCategories;
        this.getNewLayerCount();
      }
    });
  }

  private getNewLayerCount() {
    if (this.newLayerCategories) {
      const newLayerIds = this.fetchNewLayerIds(this.newLayerCategories);

      const previousIdsString = localStorage.getItem('previousLayerIds');
      const previousIds = previousIdsString
        ? JSON.parse(previousIdsString)
        : [];

      const arraysAreDifferent = !this.arraysAreEqual(newLayerIds, previousIds);

      if (arraysAreDifferent) {
        localStorage.setItem('previousLayerIds', JSON.stringify(newLayerIds));

        this.newLayerCount = newLayerIds.length;
        this.newLayerCountVisible = this.newLayerCount > 0;
      }
    }
  }

  private arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  private fetchNewLayerIds = (
    categories: MapReferenceLayerCategory[]
  ): number[] => {
    const filterCategories = (
      categories: MapReferenceLayerCategory[]
    ): (number | MapReferenceLayerCategory)[] => {
      const result: (number | MapReferenceLayerCategory)[] = [];

      categories.forEach(category => {
        const newLayers = category.layers.filter(layer => layer.isNew);
        const subCategories = category.subCategories
          ? filterCategories(category.subCategories)
          : [];
        if (newLayers.length > 0) {
          newLayers.forEach(newLayer => {
            result.push(newLayer.layerId);
          });
        }
        if (subCategories.length > 0) {
          result.push(...subCategories);
        }
      });
      return result;
    };
    return filterCategories(categories).filter(
      item => typeof item === 'number'
    ) as number[];
  };

  private watchAnnotationTypes() {
    this.plannedAreaAnnotationTypesResolver
      .getSubject()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(response => {
        if (response) {
          this.annotationTypes = response;
        }
      });
  }

  private onMapEdit() {
    this.mapboxService
      .onMapEdit(this.mapObject)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(mode => {
        this.otherEdit = mode !== null;
        this.inspectorEdit = mode === MapEditMode.INSPECTOR_TOOL;
        this.measureEdit = mode === MapEditMode.MEASURING_TOOL;
        this.reachabilityEdit = mode === MapEditMode.REACHABILITY_TOOL;
      });
  }

  showToolbarTool(key: string) {
    return (
      !this.toolbarOptions ||
      !this.toolbarOptions.hasOwnProperty(key) ||
      this.toolbarOptions[key]
    );
  }

  isCurrentlyOnHub(): boolean {
    const currentUrl = this.router.url;
    return currentUrl === `/${AppRoutes.HUB}/${HubRoutes.NSW}`;
  }

  navigateToHub() {
    this.closePanels();

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.HOME_BUTTON
    });
    this.map3dViewService.reset3dView(this.mapObject);
    this.mapToolbarService.forceClearSearch(this.mapObject);
    // Check if we're already on the Hub route
    const isAlreadyOnHub = this.isCurrentlyOnHub();

    if (isAlreadyOnHub) {
      // If already on Hub page, just reset the map view
      this.mapboxService.resetToHome(this.mapObject);
    } else {
      // If on a different route, navigate to Hub without resetting the map
      this.router.navigateByUrl(AppRoutes.HUB);
    }
  }

  addPlannedArea() {
    this.closePanels();

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.ADD_PLANNED_AREA
    });

    this.router.navigate([AppRoutes.GAP, 'new']);
  }

  addAnnotation(type: PlannedAreaAnnotationTypes) {
    this.closePanels();
    this.plannedAreaAnnotationsService.createNewAnnotation(type.annotTypeId);

    this.userEventService.logUserEvent({
      category: UserEventCategories.TOOLBAR,
      event: UserEvents.ADD_ANNOTATION
    });
  }

  toggleMapInspectorTool() {
    this.closePanels();
    this.mapToolbarService.enableInspectorTool(this.mapObject);
  }

  toggleLineMeasureTool() {
    this.closePanels();
    this.mapToolbarService.enableLineMeasureTool(this.mapObject);
  }

  toggleAreaMeasureTool() {
    this.closePanels();
    this.mapToolbarService.enableAreaMeasureTool(this.mapObject);
  }

  toggleRadiusMeasureTool() {
    this.closePanels();
    this.mapToolbarService.enableRadiusMeasureTool(this.mapObject);
  }

  enableTravelTool(mode: MapMeasureMode) {
    this.closePanels();
    this.mapToolbarService.enableTravelTool(this.mapObject, mode);
  }

  enableReachabilityTool() {
    this.closePanels();
    this.mapToolbarService.enableReachabilityTool(this.mapObject);
  }

  check3dViewEnabled() {
    return this.map3dViewService.is3dViewEnabled();
  }
  toggleSchools3dView() {
    this.closePanels();
    // TO DO - Potentially move this into map toolbar service
    this.map3dViewService.toggle3dView(this.mapObject);
  }

  toggleStreetView() {
    this.closePanels();

    if (this.mapObject && this.streetViewObject) {
      this.streetViewEnabled = !this.streetViewEnabled;
      if (this.streetViewEnabled) {
        this.mapToolbarService.enableStreetView(
          this.streetViewObject,
          this.mapObject
        );
      } else {
        this.mapToolbarService.disableStreetView(this.streetViewObject);
      }
    }
  }

  toggleReferencePanel() {
    this.isPanelOpen = !this.isPanelOpen;
  }

  closeReferencePanel() {
    this.isPanelOpen = false;
  }

  cancelEditing() {
    this.onToolClickEvent('cancel');
  }

  toggleReferenceLayerPanel() {
    this.referenceLayerPanelEnabled = !this.referenceLayerPanelEnabled;

    if (this.referenceLayerPanelEnabled && this.basemapPanelEnabled) {
      this.basemapPanelEnabled = false;
    }
    this.newLayerCount = 0;
    this.newLayerCountVisible = false;
    localStorage.setItem(
      'referenceLayerPanelClicked',
      this.referenceLayerPanelEnabled ? 'true' : 'false'
    );
  }

  checkPanelClickedState() {
    const panelClicked = localStorage.getItem('referenceLayerPanelClicked');

    if (panelClicked === 'true') {
      this.newLayerCountVisible = false;
    } else {
      this.newLayerCountVisible = panelClicked === 'false';
    }
  }

  toggleBasemapPanel() {
    this.basemapPanelEnabled = !this.basemapPanelEnabled;

    if (this.referenceLayerPanelEnabled && this.basemapPanelEnabled) {
      this.referenceLayerPanelEnabled = false;
    }
  }

  exportMapImage() {
    this.downloadDisclaimerService.showDownloadDisclaimer(() => {
      this.closePanels();
      this.mapToolbarService.captureAndSaveImage(this.mapObject);
    });
  }

  toggleFullscreen() {
    this.mapToolbarService.toggleFullscreen(this.mapObject);
  }

  referenceLayerPanelClosed(e) {
    if (e) {
      this.referenceLayerPanelEnabled = false;
    }
  }

  basemapPanelClosed(e) {
    if (e) {
      this.basemapPanelEnabled = false;
    }
  }

  private closePanels() {
    if (this.basemapPanelEnabled) {
      this.basemapPanelEnabled = false;
    }

    if (this.referenceLayerPanelEnabled) {
      this.referenceLayerPanelEnabled = false;
    }
  }

  private onToolClickEvent(tool: string) {
    this.toolClick.emit(tool);
  }
}
